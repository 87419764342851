<template>
  <section>
    <a-row class="list-container scroll-container">
      <p class="wrap-unlock">
        <a-icon type="unlock" class="m-r-10" />
        <span class="m-r-8">已解锁</span>
        <a-icon type="caret-down" class="m-r-10" />
      </p>
      <a-row type="flex" :gutter="30">
        <a-col
          :span="6"
          v-for="(item, ind) in navLists"
          :key="ind"
          @mouseenter="enter(ind)"
          @mouseleave="leave()"
        >
          <a
            :href="item.path"
            v-if="
                            item.path.indexOf('/common-library/') > -1 ||
                                item.path.indexOf('/quality-supervise/') > -1 ||
                                item.path.indexOf('/basic/') > -1
                        "
          >
            <a-row class="list-item">
              <img :src="item.icon" alt />
              <p>{{ item.title }}</p>
              <span>{{ item.decs }}</span>
            </a-row>
          </a>
          <router-link
            :to="item.path"
            :target="item.path.indexOf('/bim') > -1 ? '_blank' : ''"
            v-else
          >
            <a-row class="list-item">
              <img :src="item.icon" alt />
              <p>{{ item.title }}</p>
              <span>{{ item.decs }}</span>
            </a-row>
          </router-link>
        </a-col>
      </a-row>
    </a-row>
  </section>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      navLists: [
        {
          // title: '人员车辆管理系统', 
          title: '人员管理系统',
          path: '/pcms',
          icon: require('../assets/image/home/pcms.png'),
          // decs: '实名制|分账制'
          decs: '实名制'
        },
        {
          // title: '机械设备管理系统',
          title: '设备管理系统',
          path: '/mdms',
          icon: require('../assets/image/home/mdms.png'),
          // decs: '大型机械设备监测'
          decs: '机械设备管理及监测'
        },
        // {
        //     title: '材料管理系统',
        //     path: '/mms',
        //     icon: require('../assets/image/home/mms.png'),
        //     decs: '材料验收|供应商评分'
        // },
        {
          title: '广播管理系统',
          path: '/spms',
          icon: require('../assets/image/home/spms.png'),
          decs: '制度宣导|一触即达'
        },
        {
          title: '环境监测',
          path: '/environmental',
          icon: require('../assets/image/home/env.png'),
          decs: '环境天气设备预警'
        },
        // {
        //   title: '质量系统通用版',
        //   path: '/qsms',
        //   icon: require('../assets/image/home/qsms.png'),
        //   decs: '质量问题巡检'
        // },
        // {
        //   title: '质量监管管理系统',
        //   path: '/wisdom-building/quality-supervise/qa/works',
        //   icon: require('../assets/image/home/qsms.png'),
        //   decs: '质量问题巡检'
        // },
        // {
        //   title: '安全系统通用版',
        //   path: '/ssms',
        //   icon: require('../assets/image/home/ssms.png'),
        //   decs: '安全隐患治理'
        // },
        // {
        //     title: '安全生产管理系统',
        //     path: '/ssms',
        //     icon: require('../assets/image/home/ssms.png'),
        //     decs: '安全生产八要素'
        // },
        // {
        //     title: '计划进度管理系统',
        //     path: '/ssms',
        //     icon: require('../assets/image/home/ssms.png'),
        //     decs: '形象化进度|精细管理'
        // },
        // {
        //   title: '塔吊',
        //   path: '/tower',
        //   icon: require('../assets/image/home/tower.png'),
        //   decs: '塔吊设施设备统计'
        // },
        // {
        //   title: '出入口',
        //   path: '/enter',
        //   icon: require('../assets/image/home/exit.png'),
        //   decs: '出入口设备统计'
        // },
        // {
        //   title: '物联统计',
        //   path: '/webThing',
        //   icon: require('../assets/image/home/webThing.png'),
        //   decs: '物联设施设备统计'
        // },
        // { title: '首页大屏', path: '/monitor', icon: require('../assets/image/home/cb.png') },
        // {
        //   title: '大数据应用平台',
        //   path: '/bigdata',
        //   icon: require('../assets/image/home/bigdata.png'),
        //   decs: '应用数据汇总统计'
        // },

        // {
        //   title: '图纸库',
        //   path: '/wisdom-building/common-library/drawing/manage',
        //   icon: require('../assets/image/home/paper.png'),
        //   decs: '公共基础库配置'
        // },
        // {
        //   title: '工程库',
        //   path: '/wisdom-building/common-library/project/quality',
        //   icon: require('../assets/image/home/project.png'),
        //   decs: '公共基础库配置'
        // },
        // {
        //   title: '区域库',
        //   path: '/wisdom-building/common-library/area/manage',
        //   icon: require('../assets/image/home/region.png'),
        //   decs: '公共基础库配置'
        // },

        // {
        //   title: '组织架构',
        //   path: '/wisdom-building/basic/managementStructure',
        //   icon: require('../assets/image/home/bigdata.png'),
        //   decs: '组织架构设置'
        // },
        // {
        //   title: '权限系统',
        //   path: '/wisdom-building/basic/jurisdictionAdmin',
        //   icon: require('../assets/image/home/bigdata.png'),
        //   decs: '人员权限系统配置'
        // },
        // {
        //   title: 'BIM',
        //   path: '/bim',
        //   icon: require('../assets/image/home/bigdata.png'),
        //   decs: 'BIM可视化'
        // },
        {
          title: '项目信息管理系统',
          path: '/pims',
          icon: require('../assets/image/home/pims.png'),
          decs: '项目信息|基础管理'
        },
        {
          title: '视频监控',
          path: '/vedioMonitor',
          icon: require('../assets/image/home/bigdata.png'),
          decs: '所见即所得'
        },
        {
          title: '会展大屏',
          path: '/tvScreen',
          icon: require('../assets/image/home/cb.png'),
          decs: '会展营区项目大屏'
        },
        {
          title: '中央大屏',
          path: '/monitor',
          icon: require('../assets/image/home/cb.png'),
          decs: '项目概览中央大屏'
        },
        {
          title: '预警中心',
          path: '/collision-alarm',
          icon: require('../assets/image/home/webThing.png'),
          decs: '预警中心'
        },

        // { title: '质量监管', path: '/wisdom-building/quality-supervise', icon: require('../assets/image/home/pims.png'),decs:'质量监管' },
        // { title: '区域库', path: '/engineering', icon: require('../assets/image/home/bigdata.png'),decs:'应用数据汇总统计' },
        // { title: '工程库', path: '/engineering', icon: require('../assets/image/home/bigdata.png'),decs:'应用数据汇总统计' },
      ]
    }
  },
  created() {
    this.QueryPicServer()

    //this.queryUserDetails()
  },
  methods: {
    ...mapActions(['QueryPicServer']),
    queryUserDetails() {
      this.$api
        .queryUserDetails({ id: this.$store.state.userInfo.userId })
        .then((res) => {
          if (res.code === 200) {
            let _data = res.data || {}
            this.$store.commit(
              'SET_COMPANYID',
              _data.company_id
            )
            this.$store.commit('SET_USERNICKNAME', _data.name)
            this.$store.commit('SET_PHONE', _data.mobile)
          } else {
            this.$message.error('获取账号信息异常')
          }
        })
    },
    enter(ind) {
      this.activeInd = ind
    },
    leave() {
      this.activeInd = null
    }
  }
}
</script>

<style lang="scss" scoped>
.list-container {
  padding: 0 6%;
  height: 100%;
  .wrap-unlock {
    font-size: 18px;
    font-weight: bold;
    color: rgb(82, 154, 255);
    padding: 10px;
    margin-bottom: 10px;
    background: rgba(29, 94, 155, 0.08);
    border: 1px solid rgba(29, 94, 155, 0.2);
  }
  .list-item {
    background: url("../assets/image/home/bg-item.png") no-repeat;
    background-size: 100% 100%;
    text-align: center;
    padding: 50px 0 28px 0;
    margin-bottom: 30px;
    min-width: 220px;
    max-width: 400px;
    cursor: pointer;
    &:hover {
      img {
        opacity: 1;
        // box-shadow: 1px 1px 10px rgba(255,255,255,0.2);
      }
      p {
        color: rgb(82, 154, 255);
      }
    }
    img {
      width: 24%;
      margin-bottom: 20px;
      opacity: 0.7;
    }
    p {
      font-size: 17px;
      font-weight: 600;
      letter-spacing: 1px;
      color: #ffffff;
      margin-bottom: 8px;
    }
    span {
      color: #c6c6c6;
    }
  }
}
</style>
